.banner {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-header-haslang {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

a.btn-border {
  border: 2px solid #000;
  border-radius: 0;
  background: #fff;
}

.langHeader {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  justify-content: end;
}

.flex {
  margin-top: 50px;
}

.image {
  margin: 5px;
}

.wiki {
  max-width: 80vw;
}

.simpleLink {
  color: #ffffff;
}

/* Home画面の遷移リンク */
.button_line004 a {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  max-width: 200px;
  padding: 10px 40px;
  color: #ffffff;
  transition: 0.3s ease-in-out;
  font-weight: 600;
}
.button_line004 a:before,
.button_line004 a:after {
  content: "";
  width: 18px;
  height: 18px;
  border-color: #477a65;
  box-sizing: border-box;
  border-style: solid;
  display: block;
  position: absolute;
  transition: all 0.3s ease-in-out;
}
.button_line004 a:before {
  top: -6px;
  left: -6px;
  border-width: 2px 0 0 2px;
}
.button_line004 a:after {
  bottom: -6px;
  right: -6px;
  border-width: 0 2px 2px 0;
}
.button_line004 a:hover:before,
.button_line004 a:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: #477a65;
}
.button_line004 a:hover {
  color: #fff;
  background-color: #477a65;
  border-color: #477a65;
}

.progress {
  margin: 10px;
}

.Poricy {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Poricy-body {
  max-width: 50vw;
  margin: 10vh;
}

.backimage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 10px;
}

.languageimage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.languageimage:hover {
  opacity: 0.7;
}

.back {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.back:hover {
  background-color: #3f434b;
}

hr.solid {
  border-top: 1px solid #fff;
}

@media (max-width: 1200px) {
  .Poricy-body {
    max-width: 70vw;
    margin: 10vh;
  }
}

/*セレクトボックス系*/
.selectbox-5 {
  display: inline-flex;
  align-items: center;
  position: relative;
  min-width: 50px;
}

@media (min-width: 1000px) {
  .selectbox-5 {
    min-width: 100px;
  }
}

.selectbox-5::after {
  position: absolute;
  right: 15px;
  width: 10px;
  height: 7px;
  background-color: #282c34;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  content: "";
  pointer-events: none;
}

.selectbox-5 select {
  appearance: none;
  min-width: 100px;
  height: 2.8em;
  padding: 0.4em calc(0.8em + 30px) 0.4em 0.8em;
  border: none;
  background-color: #282c34;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
}

.selectbox-5 select:focus {
  outline: none;
}

.content {
  position: relative;
  z-index: 9996;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.685);
  z-index: 9997;
}

.modal {
  z-index: 9998;
  position: relative;
  width: 100%;
  height: 100%;
}
